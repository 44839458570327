import React, { Fragment } from "react";
import './clock.css'

const Clock = ({ timerDays, timerHours, timerMinutes, timerSeconds }) => {
    return (
        <Fragment>
            <section className="timer-container">
                <section className="timer">
                    <div className="clock">
                        <br />
                        <br />
                        <section>
                            <p>{timerDays}</p>Days
                            <p>{timerHours}</p>Hours
                            <p>{timerMinutes}</p>Minutes
                            <p>{timerSeconds}</p>Seconds
                        </section>
                    </div>
                </section>
            </section>
        </Fragment>
    );
};

Clock.defaultProps = {
    timerDays: 10,
    timerHours: 10,
    timerMinutes: 10,
    timerSeconds: 10,
};

export default Clock;