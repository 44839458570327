import './App.css';
import { BrowserRouter as Router, Switch, Link, Route } from 'react-router-dom'

import DunbaWorld from './components/DunbaWorld';
import Countdown from './components/Countdown';



function App() {
  return (
    <div className="App">

      <Router>
        <Switch>
          <Route exact component={DunbaWorld} path="/" />
          <Route exact component={Countdown} path="/countdown" />

        </Switch>
      </Router>

    </div>
  );
}

export default App;
