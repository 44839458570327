import React, { useState, useEffect } from "react";
import '../components/world.css'
import AddIcon from '@mui/icons-material/Add';


const DunbaWorld = () => {

    const [isNameDisplayed, setisNameDisplayed] = useState(true);
    const showName = () => {
        isNameDisplayed ? setisNameDisplayed(false) : setisNameDisplayed(true)

    }

    const alertHandler = () => {
        const holder = Math.floor(Math.random() * 100)
        console.log(holder)
        if (holder % 2 == 0) {
            alert('Congratulations! you are the 1000th visitor.')

        }
    }

    const musiclinks = [{ site: "Spotify", url: "https://dunba.world/spotify", icon: "white", hex: '#1DB954' }, { site: "Apple Music", url: "https://dunba.world/applemusic", icon: "white", hex: '#fc3c44' }, { site: "Soundcloud", url: "https://soundcloud.com/dunba", icon: "white", hex: '#ff7700 ' }]
    const socials = [{ site: "Instagram", url: "https://dunba.world/instagram", icon: "white" }, { site: "Twitter", url: "https://dunba.world/twitter", icon: "white" }, { site: "Email", url: "mailto:hello@dunba.world", icon: "white" }]
    const socials2 = [{ site: "Instagram", url: "https://dunba.world/instagram", icon: "white" }, { site: "Twitter", url: "https://dunba.world/twitter", icon: "white" }, { site: "Email", url: "mailto:hello@dunba.world", icon: "white" }]


    return (
        <div className='flexcontainer'>
            <div className='linktreeholder'>
                <div className='nameheader'>
                    {isNameDisplayed ? <h1 onClick={showName}>DUNBA</h1> : <h1 onClick={showName}>@dunbatopaz</h1>}
                    <p onClick={alertHandler}>🎹,🎥</p>
                </div>
                <h4>NEW MUSIC 🚨💿</h4>
                <div className='socialsheader'>
                    {musiclinks.map((link, index) => (<p><a target='_blank' id='sociallink' href={link.url}><p id='socialplate' style={{ color: link.hex, fontWeight: 'bold' }}>{link.site}</p></a></p>))}
                </div>
                <br />
                <br />
                <h4>CONTACT</h4>
                <div className='socialsheader'>
                    {socials2.map((social, index) => (<p><a target='_blank' id='sociallink' href={social.url}><p id='socialplate'>{social.site}</p></a></p>))}
                </div>

            </div>



        </div>

    )
}


export default DunbaWorld